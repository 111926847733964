import { createSlice } from '@reduxjs/toolkit';

const create_message = (level, message) => {
  const id = new Date().getTime()
  return {
    id,
    level,
    message,
    opened: false,
    is_loading: false,
    event_trigger: null,
  }
}

export const appSlice = createSlice({
  name: 'app',
  initialState: {
    name: 'app message',
    systemMessages: [
      {
          category: "あなたへのお知らせ",
          subject: 'お盆期間中の営業について',
          notified_at: '2023年08月05日',
          message: `<p>
              PR-Viewをご利用いただき誠にありがとうございます。<br />
              <br />
              お盆期間中の営業につきまして、誠に勝手ながら2023年8月11日(金)～8月15日(火)までを休業とさせていただきます。<br />
              <br />
              お客様にはご不便をおかけ致しますが、何卒ご了承の程お願い申し上げます。
          </p>`,
      },
    ],
    alertMessages: [],
    modal: null,
    pvPrompt: null,
    availableResources: {},
  },
  reducers: {
    alert_info: (state, action) => {
        state.alertMessages.push(create_message('info', action.payload))
    },
    clean: (state) => {
      state.alertMessages = []
    },
    opened: (state, action) =>{
      state.alertMessages.find(am => am.id === action.payload).opened = true
    },
    modal: (state, action) => {
      
      state.modal = action.payload
    },
    update_available_resources: (state, action) => {
      state.availableResources = action.payload
    },
    loading: (state, action) => {
      state.is_loading = action.payload
    },
    pv_prompt: (state, action) => {
      if(action.payload === null){
        state.pvPrompt = null
      }else{
        state.pvPrompt = {
          message: action.payload.message || null,
          default_value: action.payload.default_value || null,
          placeholder: action.payload.placeholder || null,
          callback: action.payload.callback || null,
        }
      }
    }
  },
})

export const { alert_info, clean, opened, modal, loading, pv_prompt, update_available_resources} = appSlice.actions

export default appSlice.reducer