import { createSlice } from '@reduxjs/toolkit';

export const mediumListsSlice = createSlice({
  name: 'medium-lists',
  initialState: {
    initialize: false,
    is_loading: false,
    event_trigger: null,
    data: [],
  },
  reducers: {
    insert: (state, {payload = []}) => {
      payload.forEach(d => {
        if(!state.data.find(sd => sd.id == d.id)) state.data.push(d)
      })
      state.data = state.data.sort((a, b) => {
        const a_time = new Date(a.updated_at.replace('T', ' ')).getTime()
        const b_time = new Date(b.updated_at.replace('T', ' ')).getTime()
        return b_time - a_time
      })
    },
    getAll: (state, action) => {
      // action
    },
    getOne: (state, action) => {

    },
    create: (state, action) => {
      if(state.data.find(d => d.id === action.payload.id)){
        state.data = state.data.map(sd => sd.id === action.payload.id ? action.payload : sd)
      }else{
        state.data.unshift(action.payload)
      }
      state.event_trigger = 'created'
    },
    update: (state, action) => {
      // const m = action.payload
      // state.data = state.data.map(d => d.id === m.id ? m : d)
      const m = action.payload
      state.data = state.data.map(d => {
        if(d.id === m.id){
          for(let key in d){
            d[key] = m[key]
          }
        }
        return d
      }).sort((a, b) => {
        const a_time = new Date(a.updated_at).getTime()
        const b_time = new Date(b.updated_at).getTime()
        return b_time - a_time
      })
      state.event_trigger = 'updated'
    },
    remove: (state, action) => {      
      (async () => {
        const medium_list = action.payload
        state.data = state.data.filter(d => d.id !== medium_list.id)
        state.is_loading = false
      })()
    },
    loading: (state, action) => {
      state.is_loading = action.payload
    },
    event_reset: (state) => {
      state.event_trigger = null
    },
    copy: (state, action) => {
      const {origin_medium_list_id, new_medium_list_name} = action.payload
      const medium_list = state.data.find(d => d.id === origin_medium_list_id)
      const new_medium_list = JSON.parse(JSON.stringify(medium_list))
      new_medium_list.name = new_medium_list_name
      new_medium_list.id = 'ml' + (new Date().getTime())
      new_medium_list.updated_at = new Date().toISOString().slice(0,19)
      new_medium_list.delivered = false
      state.data.push(new_medium_list)
    }
  },
})

export const { insert, create, getAll, getOne, remove, loading, event_reset, update, copy, } = mediumListsSlice.actions

export default mediumListsSlice.reducer