import React, { Suspense, lazy } from "react"
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from "react-router-dom"
import { store } from "./redux/store"
import { Provider } from "react-redux"

document.title = 'PR-View'

const App = lazy(
  () =>
    new Promise((resolve) => {
      setTimeout(async () => {
        return resolve(import("./views"))
      }, 0);
    })
)

document.querySelector('html').setAttribute('lang','ja')
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <Provider store={store}>
      <Suspense>
          <BrowserRouter>
              <App />
          </BrowserRouter>
      </Suspense>
    </Provider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
