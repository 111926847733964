import { createSlice } from '@reduxjs/toolkit';

export const profileSlice = createSlice({
  name: 'profile',
  initialState: {
    name: 'profile',
    data: {},
  },
  reducers: {
    insert: (state, action) => {
      state.data = {
        ...state.data,
        ...action.payload,
      }
      
    },
    update: (state, action) => {
      const profile = action.payload
      state.data.contractor_name = profile?.contractor_name
      state.data.company_name = profile?.company_name
      state.data.address_postal_code = profile?.address_postal_code
      state.data.address_line_1 = profile?.address_line_1
      state.data.address_line_2 = profile?.address_line_2
    },
    change_plan: (state, action) => {
        const plan_id = action.payload
        if(plan_id === "basic"){
            state.plan = {
                id: "002",
                name: "basic",
            }
        }else if(plan_id === "premium"){
            state.plan = {
                id: "001",
                name: "premium",
            }
        }
        
    },
    loading: (state, action) => {
      state.is_loading = action.payload
    },
  },
})

export const { insert, update, loading, change_plan } = profileSlice.actions

export default profileSlice.reducer