
import { createSlice } from '@reduxjs/toolkit';

export const mediaSlice = createSlice({
  name: 'medium-remarks',
  initialState: {
    initialize: false,
    event_trigger: null,
    data: [],
  },
  reducers: {
    insert: (state, {payload = []}) => {
      payload.forEach(d => {
        if(!state.data.find(sd => sd.id == d.id)) state.data.push(d)
      })
    //   state.data = state.data.sort((a, b) => {
    //     const a_time = new Date(a.updated_at).getTime()
    //     const b_time = new Date(b.updated_at).getTime()
    //     return b_time - a_time
    //   })
    },
    // getById: (state) => {
    //     // state.alertMessages.push(create_message('info', "hello eisuke !!!!!"))
    // },
    // getOne: (state, action) => {

    // },
    // create: (state, action) => {
    //   if(state.data.find(d => d.id === action.payload.id)){
    //     state.data = state.data.map(sd => sd.id === action.payload.id ? action.payload : sd)
    //   }else{
    //     state.data.unshift(action.payload)
    //   }
    //   state.event_trigger = 'created'
    // },
    // update: (state, action) => {
    //   const m = action.payload
    //   state.data = state.data.map(d => {
    //     if(d.id === m.id){
    //       for(let key in m){
    //         d[key] = m[key]
    //       }
    //     }
    //     return d
    //   })
    // },
    // remove: (state, action) => {
    //   const medium = action.payload
    //   state.data = state.data.filter(d => d.id !== medium.id)
    // },
    // event_reset: (state) => {
    //   state.event_trigger = null
    // }
  },
})

export const { insert, update } = mediaSlice.actions

export default mediaSlice.reducer
