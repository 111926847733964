import { configureStore } from '@reduxjs/toolkit'

import appSlice from '../app'
import mediumListsSlice from '../medium-lists'
import mediaSlice from '../media'
import profileSlice from "../profile"
import mediumRemarksSlice from "../medium-remarks"

export const store = configureStore({
  reducer: {
    // account,
    app: appSlice,
    mediumLists: mediumListsSlice,
    media: mediaSlice,
    profile: profileSlice,
    mediumRemarks: mediumRemarksSlice,
  },
});
